





















































import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import ExportEventsRequestModel from "@/tools/models/export-events/ExportEventsRequestModel";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: { DatesPicker },
})
export default class ExportEventsView extends Vue {
  @Ref("form") form!: VueForm;

  readonly requiredRuleForArray = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  localValue = new ExportEventsRequestModel();
  savedIdsItems: Array<string> = [];
  valid = true;

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isExportingInProgress(): boolean {
    return this.$store.state.exportEventsStore.isExportingInProgress;
  }

  @Watch("localValue.savedIds", { deep: true })
  private watchLocalValueSavedIds(
    newValue: Array<string>,
    oldValue: Array<string>
  ) {
    if (newValue.length > oldValue.length) {
      this.savedIdsItems.push(newValue[newValue.length - 1]);
    }
  }

  exportEventsFile() {
    this.$store.dispatch("exportEventsFile", {
      appId: this.applicationId,
      payload: this.localValue,
    });
  }
}
